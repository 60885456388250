<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog
        modal-dialog-centered
        modal-xl
        modal-fullscreen-lg-down
        modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <div class="row modal-title w-100">
            <h5 class="col-auto" id="staticBackdropLabel">
              {{ $t("manufacturingModal.manufacturing") }}
            </h5>
            <div class="col">
              <div class="bold-14">{{ name }}</div>
              <div class="regular-12">
                {{ $t("manufacturingModal.oneProductTime") }}
                <span class="bold-12">{{ stepsTime / 60 }}m</span>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-for="(step, i) in steps"
            :key="step.id"
            class="
              row
              custom-input
              mx-md-5
              px-md-5
              whitebg-input
              align-items-center
            "
          >
            <div class="col-3">
              <div class="mb-1">
                <label class="bold-12 text-gray">
                  {{ ordinal_suffix_of(step.order) }}
                </label>
              </div>
              <div class="mb-3">
                <label class="bold-12">
                  {{ step.name }}
                </label>
              </div>
            </div>
            <div class="col-2">
              <div class="mb-3">
                <label class="form-label bold-12 text-gray">{{
                  $t("incomingProducts.totalTime")
                }}</label>
                <div class="mt-2 semibold-16">
                  {{ Math.round((step.time * total) / 60) }}m
                </div>
              </div>
            </div>
            <div class="col-2 col-xxxl-1_5">
              <div class="mb-3">
                <label class="form-label bold-12 text-gray">
                  {{ $t("manufacturingModal.done") }}
                </label>
                <div class="mt-2 semibold-16">
                  {{ doneVal(step.id) }}
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="mb-3">
                <label class="form-label bold-12 text-gray">
                  {{ $t("manufacturingModal.stillLeft") }}
                </label>
                <div class="mt-2 semibold-16">
                  {{ $t(`cataloge.key`, { expr: total - doneVal(step.id) }) }}
                </div>
              </div>
            </div>
            <div class="col-2 col-xxxl-2_5">
              <div class="mb-3">
                <label class="form-label bold-12 text-gray">
                  {{ $t("manufacturingModal.newlyCompleted") }}
                </label>
                <input
                  type="number"
                  class="form-control"
                  v-model="doneNow[step.id]"
                  :min="0"
                  :max="total - doneVal(step.id, false)"
                />
              </div>
            </div>
            <div class="col-1">
              <div class="mb-0">
                <button
                  class="btn btn-outline-primary"
                  v-if="showAddBtn(i)"
                  v-on:click="addNumber(step.id, addMax(i, true))"
                >
                  +{{ addMax(i, true) }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-6">
              <label class="cstm-check d-flex">
                <span class="regular-12 text-black mt-3">
                  {{ $t("manufacturingModal.theRemainingItemsAreWaste") }}
                </span>
                <input type="checkbox" v-model="waste" :value="true" />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
            <div class="col-6 text-end">
              <button
                class="btn btn-primary"
                v-if="saveProcess || canSave()"
                v-on:click="save()"
                :disabled="saveProcess"
                :class="{ 'loading-spin': saveProcess }"
              >
                <span>{{ $t("finance.save") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import store from "@/store";
const bootstrap = require("bootstrap");

export default {
  name: "ManufacturingModal",
  props: {
    modelValue: Array,
    id: Number,
    name: String,
    steps: Array,
    done: Object,
    warehouseOperationId: Number,
    total: Number,
  },
  data() {
    return {
      saveProcess: false,
      waste: false,
      doneNow: {},
    };
  },
  computed: {
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    stepsTime() {
      let time = 0;
      for (const step of this.steps) {
        time += step.time;
      }
      return time;
    },
  },
  methods: {
    ordinal_suffix_of(i) {
      if (this.$i18n.locale !== "en") {
        return i + ".";
      }
      let j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st";
      }
      if (j === 2 && k !== 12) {
        return i + "nd";
      }
      if (j === 3 && k !== 13) {
        return i + "rd";
      }
      return i + "th";
    },
    canSave() {
      for (const i in this.doneNow) {
        if (this.doneNow[i] !== 0) {
          return true;
        }
      }
      return this.waste;
    },
    save() {
      this.saveProcess = true;
      http
        .fetch("/manufacturing/operations/done", {
          waste: this.waste ? 1 : 0,
          item_id: this.id,
          operation_id: this.warehouseOperationId ?? this.$route.params.id,
          done: this.doneNow,
        })
        .then((data) => {
          for (const i in this.doneNow) {
            this.doneNow[i] = 0;
          }
          this.$emit("update:total", data.total);
          delete data.total;
          this.$emit("update:done", data);
          this.saveProcess = false;
          this.myModal.hide();
        })
        .catch(() => {
          this.saveProcess = false;
          store.commit("setApiError", { message: "Saving error" });
        });
    },
    doneVal(id, now = true) {
      if (now) {
        return (this.done[id] ?? 0) + (this.doneNow[id] ?? 0);
      }
      return this.done[id] ?? 0;
    },
    addNumber(id, number) {
      if (this.doneVal(id) < this.total) {
        this.doneNow[id] += number;
      }
    },
    addMax(i, now = false) {
      if (i === 0) {
        return this.total - this.doneVal(this.steps[i].id, now);
      }
      if (this.doneVal(this.steps[i].id) < this.doneVal(this.steps[i - 1].id)) {
        return (
          this.doneVal(this.steps[i - 1].id, now) -
          this.doneVal(this.steps[i].id, now)
        );
      }

      return 0;
    },
    showAddBtn(i) {
      if (i === 0) {
        return this.doneVal(this.steps[i].id) < this.total;
      }
      if (this.doneVal(this.steps[i].id) < this.doneVal(this.steps[i - 1].id)) {
        return this.doneVal(this.steps[i].id) < this.total;
      }

      return false;
    },
    showModal() {
      for (const step of this.steps) {
        this.doneNow[step.id] = 0;
      }
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
  },
  emits: ["update:modelValue", "update:done", "update:total"],
};
</script>
