<template>
  <div class="align-items-center" v-if="value">
    <button class="btn btn-secondary btn-sm" v-on:click="showModal()">
      <BaseIcon name="ruler" />
    </button>
    <span class="ms-3"
      >{{
        Math.round((total / (value.total * value.steps.length)) * 100)
      }}%</span
    >
  </div>
  <ManufacturingModal
    v-if="value"
    ref="modal"
    :steps="value?.steps"
    :id="value?.item_id ?? id"
    :warehouseOperationId="value?.warehouse_operation_id"
    :name="value?.title"
    v-model:total="value.total"
    v-model:done="value.done"
  />
</template>
<script>
import BaseIcon from "@/components/icons/BaseIcon";
import ManufacturingModal from "@/components/modals/ManufacturingModal";
export default {
  name: "Production",
  components: { ManufacturingModal, BaseIcon },
  props: {
    id: Number,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  computed: {
    total() {
      let total = 0;
      for (const i in this.value.done) {
        total += this.value.done[i];
      }
      return total;
    },
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    showModal() {
      this.$refs.modal.showModal();
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
